.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  width: 100px;
  pointer-events: none;
  margin-bottom: 20px; /* Добавляем отступ вниз */
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textarea {
  width: 350px; /* Задает ширину текстового поля */
  height: 150px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box; /* Обеспечивает правильный расчет ширины с учетом отступов и полей */
}

.error {
  color: red;
  margin-top: 10px;
}

.file-input {
  display: none; /* Скрываем стандартный input */
}

.file-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-input-label {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.file-input-label:hover {
  background-color: #21a1f1;
}

.file-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-name {
  font-size: 14px;
  color: #ccc;
  text-align: left;
  margin-right: 10px;
}

.remove-button {
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  background-color: #ff4d4d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #ff1a1a;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #21a1f1;
}

.progress-bar {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.message {
  margin-top: 10px;
  font-size: 16px;
  color: yellow;
}

.remaining-time {
  margin-top: 10px;
  font-size: 16px;
  color: #ccc;
}

.privacy-policy {
  margin-top: 10px;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}
